import breakpoints from '@/breakpoints';
import Header from '@/components/elements/Header/Header';
import Label from '@/components/elements/Label/Label';
import Input from '@/components/elements/forms/Input/Input';
import Select from '@/components/elements/forms/Select/Select';
import ListInputSelect from '@/components/elements/forms/listInput/ListInputSelect/ListInputSelect';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';

const baseTranslationKey = 'components.modules.checkout.giftcards.SelectAmount';

const AVAILABLE_AMOUNTS_GIFTCARD = [
  { value: 2000, popular: false },
  { value: 1500, popular: true },
  { value: 1000, popular: false },
] as const;

const AVAILABLE_AMOUNTS_WELLNESSCARD = [
  { value: 5000, popular: false },
  { value: 3500, popular: true },
  { value: 2000, popular: false },
] as const;

type Props = {
  title: string;
  type: 'giftcard' | 'wellnesscard';
};

const isValidPositiveWholeInt = (value: string) => {
  return /^\d+$/.test(value.trim());
};

const SelectAmount = ({ title, type }: Props) => {
  const { isMobileView, windowSize } = useMobileView();
  const { register, setValue, formdata, errors } = useGiftcardCheckoutFormData();
  const selectedColor = type === 'giftcard' ? 'bg-secondary-900' : 'bg-success-900';
  const unSelectedColor = type === 'giftcard' ? 'bg-secondary-50' : 'bg-success-50';
  const MAX_AMOUNT_OF_GIFTCARD = 10;
  const selectedPreset = formdata.amount ? formdata.amount : 0;

  const handleCustomAmountChange = (amount: string) => {
    if (isValidPositiveWholeInt(amount)) {
      const isPreselectedValue = (
        type === 'giftcard' ? AVAILABLE_AMOUNTS_GIFTCARD : AVAILABLE_AMOUNTS_WELLNESSCARD
      ).some((availableAmount) => availableAmount.value === parseInt(amount));
      if (isPreselectedValue) {
        setValue('amount', parseInt(amount));
      } else {
        setValue('customAmount', parseInt(amount));
        setValue('amount', undefined);
      }
    } else {
      setValue('customAmount', undefined);
      setValue('amount', 1500);
    }
  };

  const handleOnSelectedPresetAmount = (amount: number) => {
    setValue('amount', amount);
    setValue('customAmount', undefined);
  };

  return (
    <CardWrapper className="w-full">
      <div className="px-lg pt-md gap-lg flex flex-col">
        <div className="py-sm">
          <Header label={title} size="md" />
        </div>
        <div role="radiogroup" className={`${isMobileView ? 'gap-sm' : 'gap-3xl'}  flex justify-center`}>
          {(type === 'giftcard' ? AVAILABLE_AMOUNTS_GIFTCARD : AVAILABLE_AMOUNTS_WELLNESSCARD).map((amount) => (
            <div className="flex flex-col justify-end" key={amount.value}>
              {amount.popular && (
                <div className="flex justify-center">
                  <Label
                    className="bg-highlight-700 rounded-b-none"
                    label={_s(`${baseTranslationKey}.popularChoice`)}></Label>
                </div>
              )}
              <button
                role="option"
                aria-selected={amount.value === selectedPreset}
                type="button"
                onClick={() => handleOnSelectedPresetAmount(amount.value)}
                className={`outline-none`}>
                <div
                  className={`${
                    amount.value === selectedPreset ? ' text-white' : ' text-black-900'
                  } py-xl px-xl relative inline-flex items-center gap-[4px] rounded-lg ${
                    amount.value === selectedPreset ? selectedColor : unSelectedColor
                  } ${
                    isMobileView || (windowSize.width > breakpoints.lg && windowSize.width < breakpoints.xl)
                      ? '!px-lg text-md'
                      : 'text-lg'
                  }`}>
                  <div className="relative flex h-[18px]">
                    <span className="flex items-center text-center">{`${`${amount.value} kr`}`}</span>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
        <input type="hidden" {...register('amount', { valueAsNumber: true })} value={selectedPreset} />
        <Input
          {...register('customAmount', {
            onChange: (event) => handleCustomAmountChange(event.currentTarget.value),
            setValueAs: (value) => (value ? parseInt(value) : undefined),
            min: {
              value: 100,
              message: _s(`${baseTranslationKey}.customAmount.error`),
            },
          })}
          placeholder={_s(`${baseTranslationKey}.customAmount.placeHolder`)}
          title={_s(`${baseTranslationKey}.customAmount.title`)}
          rightSlot={<span>kr</span>}
          error={errors.customAmount ? errors.customAmount.message : ''}
        />
        <ListInputSelect
          leftPadding={false}
          {...register('quantity', { valueAsNumber: true })}
          label={_s(`${baseTranslationKey}.quantityDropdownTitle.${type}`)}
          leftIcon={<Icon variant="gift-card-alt" />}>
          {Array.from({ length: MAX_AMOUNT_OF_GIFTCARD }, (_, index) => (
            <Select.Option key={index + 1} value={index + 1}>
              {index + 1} {_s(`${baseTranslationKey}.quantityDropdownLabel`)}
            </Select.Option>
          ))}
        </ListInputSelect>
      </div>
    </CardWrapper>
  );
};

export default SelectAmount;

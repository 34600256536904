import { UpdateUserInfoFields } from '@/components/modules/UpdateUserInfo/UpdateUserInfo';

import {
  DEFAULT_FORM_FIELD_ID,
  GUEST_FORM_FIELD_ID,
  PROFILE_FORM_FIELD_ID,
} from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { _s } from '@/locale';
import { ConfirmedBooking, SaveBookingRequest } from '@/types/api/services/booking';
import {
  AvailablePaymentMethod,
  AvailablePrePaymentMethod,
  BookingCheckoutSummary,
  CheckoutEmployee,
  CheckoutPaymentMethod,
  CheckoutSummary,
  CouponMethod,
  SelectPaymentOptionIcon,
  SelectedPaymentMethod,
  UnselectedPaymentMethod,
  availablePrePaymentRadioButtonsMethodSchema,
} from '@/types/checkout';
import { BookState, Giftcard, Giftcards } from '@/types/state/book';
import moment from 'moment';
import { allowMarketing, getBookingInitiator, getBookingStartingPoint, isMpBooking } from './analytics';
import { getPaymentMethodLabel } from './analyticsTS';
import { getDaysUntil, hasNotPasted } from './date';
import { bookingsAllowedToBeCancelled, getEmployeeCancelMargin } from './employee';
import { isServer } from './general';
import { mergeMultidimensionalObj } from './objects';
import { getIsOnlinePaymentRequired } from './onlinePayments';

import { bookActions } from '@/actions';
import { IconVariant } from '@/components/icons/types';
import {
  CheckoutDetailsBundle,
  CheckoutDetailsProps,
  CheckoutDetailsService,
} from '@/components/modules/checkout/CheckoutDetails/CheckoutDetails';
import { MINIMUM_SWISH_AMOUNT_THRESHOLD } from '@/constants/onlinePaymentConstants';
import { PaymentMethod } from '@/constants/paymentMethodConstants';
import { TERMS_NAVIGATION_LINKS, TermLinkIdentifier } from '@/constants/terms';
import { CheckoutFormData, GROUP_BOOKING_PARTICIPANTS_STORAGE_KEY } from '@/hooks/useCheckoutFormData';
import { ChargeAdjustmentItem, CheckoutTracking, PaymentMethodAvailableItem } from '@/types/analytics';
import { PaymentHistoryResponse } from '@/types/api/services/booking';
import { ClientBundle } from '@/types/api/services/users/schema';
import { PaymentCard } from '@/types/paymentcards';
import { BundleState } from '@/types/state/bundle';
import { FormField, PlaceEmployee, standardFormFieldSchema } from '@/types/state/shared';
import { captureMessage } from '@sentry/react';
import { isIOS, isMacOs, isSafari, osVersion } from 'react-device-detect';
import { z } from 'zod';
import { getCookie } from './cookies';
import {
  canPayCardOnFile,
  canPayCardOnFileAfterBooking,
  canPayKlarna,
  canPayKlarnaAfterBooking,
  canPayQliro,
  canPayQliroAfterBooking,
  canPaySwish,
  canPaySwishAfterBooking,
  getCompanyType,
  getDynamicPriceListIdKey,
  getOfferPrice,
  getPlaceTimezone,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  getStartTime,
  getTotalPrice,
  getTrackingPrices,
  isTotalPayable,
  shouldShowFrom,
  showPrices,
} from './placeHelper';

export const getSelectedPaymentDetails = (
  method: SelectedPaymentMethod | CouponMethod | UnselectedPaymentMethod,
): {
  label: string;
  icon: SelectPaymentOptionIcon;
} => {
  switch (method.type) {
    case CHECKOUT_PAYMENT_METHOD.NONE: {
      return { icon: 'pen', label: _s('choosePaymentMethod') };
    }
    case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
      return { label: _s('payAtTheSalon'), icon: 'wallet' };
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
      return { label: 'Klarna', icon: 'payment-method-klarna' };
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
      return { label: 'Qliro', icon: 'payment-method-qliro' };
    case CHECKOUT_PAYMENT_METHOD.NEW_COF:
      return { label: _s('newPaymentCard'), icon: 'payment-method-credit-card' };
    case CHECKOUT_PAYMENT_METHOD.STORED_COF:
      const { brand, lastFour } = method;
      switch (brand) {
        case 'amex':
        case 'amex_applepay':
        case 'amex_googlepay':
          return { label: `AMEX .... ${lastFour}`, icon: 'payment-method-amex' };
        case 'diners':
          return { label: `Diners .... ${lastFour}`, icon: 'payment-method-diners' };
        case 'discover':
        case 'discover_applepay':
        case 'discover_googlepay':
          return { label: `Discover .... ${lastFour}`, icon: 'payment-method-discover' };
        case 'maestro':
        case 'maestro_applepay':
        case 'maestro_googlepay':
          return { label: `Maestro .... ${lastFour}`, icon: 'payment-method-maestro' };
        case 'mc':
        case 'mc_applepay':
        case 'mc_googlepay':
          return { label: `Mastercard .... ${lastFour}`, icon: 'payment-method-mastercard' };
        case 'visa':
        case 'visa_applepay':
        case 'visa_googlepay':
          return { label: `Visa .... ${lastFour}`, icon: 'payment-method-visa' };
        case 'unknown':
          return { label: _s('paidWithUnknownCard'), icon: 'payment-method-credit-card' };
        default:
          const never: never = brand;
          captureMessage(`[getSelectedPaymentDetails]: Unknown card brand: ${never}`);
          return { label: _s('paidWithUnknownCard'), icon: 'payment-method-credit-card' };
      }
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
      return { label: 'Google Pay', icon: 'payment-method-google-pay' };
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
      return { label: 'Apple Pay', icon: 'payment-method-apple-pay' };
    case 'GIFTCARD': {
      return { label: 'Bokadirekt presentkort', icon: 'payment-method-gift-card' };
    }
    case 'VALUECARD': {
      return { label: 'Bokadirekt värdekort', icon: 'payment-method-value-card' };
    }
    case 'WELLNESSCARD': {
      return { label: 'Bokadirekt friskvårdskort', icon: 'payment-method-wellness-card' };
    }
    case CHECKOUT_PAYMENT_METHOD.SWISH:
      return { label: 'Swish', icon: 'payment-method-swish' };
    default:
      const never: never = method;
      throw new Error(`Unknown method: ${never}`);
  }
};

export const normalizeFormData = (formData: CheckoutFormData) => {
  const normalizedFormData = {};
  for (let formKey in formData) {
    let form = formData[formKey];
    if (!formKey || formKey === 'undefined') continue;
    normalizedFormData[formKey] = {};
    for (let field in form) {
      let value = form[field] || '';
      value = value.trim();
      if (field === 'nationalId') {
        let formattedNationalId = value.replace(/\D+/g, '');
        if (formattedNationalId.length === 10) {
          try {
            const year = parseInt(value.substring(0, 2), 10);
            if (year <= 10) {
              value = '20' + value;
            } else {
              value = '19' + value;
            }
          } catch (e) {
            value = '19' + value;
          }
        }
      }
      if (['mobile', 'phone'].indexOf(field) !== -1) {
        if (value[0] === '+' && value.length === 13 && value[3] === '0') {
          value = value.substring(0, 3) + value.substring(4);
        }
        if (value.length === 10) {
          value = value.replace('07', '+467');
        }
      }
      normalizedFormData[formKey][field] = value;
    }
  }

  return normalizedFormData;
};

export const getBookingCheckoutFormFields = ({
  employee,
  place,
  services,
  forceRequireEmail = false,
}: {
  employee: PlaceEmployee;
  place: BookState['place'];
  services: BookState['services'];
  forceRequireEmail?: boolean;
}): FormField[] => {
  const isVisibleField = (field: FormField, allowCustomerMessage: boolean) => {
    if (field.id === DEFAULT_FORM_FIELD_ID.BOOKING_NOTES) return allowCustomerMessage;
    /**
     * in new checkout we dont care about giftcard field since we are not using it anymore
     * as a formfield, instead it will show as a payment option if the place accepts giftcard
     */
    if (field.id === DEFAULT_FORM_FIELD_ID.GIFTCARD) return false;
    return field.visible;
  };

  const loopFields = (field: FormField) => {
    if (forceRequireEmail && field.id === DEFAULT_FORM_FIELD_ID.EMAIL) {
      return mergeMultidimensionalObj(field, { required: true });
    }
    return mergeMultidimensionalObj(field, {});
  };

  let serviceFields = [];
  (services || []).forEach((service) => {
    if (service.about && service.about.formFields && service.about.formFields.length) {
      serviceFields = serviceFields.concat(service.about.formFields);
    }
  });
  serviceFields = serviceFields.map((field) => mergeMultidimensionalObj(field, {}));

  let employeeFields = [];
  if (employee.about && employee.about.formFields && employee.about.formFields.length) {
    employeeFields = [...employee.about.formFields].map(loopFields);
  } else if (place.about?.formFields?.length) {
    employeeFields = [...place.about.formFields].map(loopFields);
  }

  const allowCustomerMessage = Boolean(employee?.about?.settings?.allowCustomerMessage);

  employeeFields = employeeFields.filter((field) => isVisibleField(field, allowCustomerMessage));
  serviceFields = serviceFields.filter((field) => isVisibleField(field, allowCustomerMessage));

  return [...employeeFields, ...serviceFields];
};

export const getBookingStartTime = (time: BookState['time']) => {
  return time ? moment.utc(time.timestamp).add(time.selected, 's').toISOString() : null;
};

export const getCheckoutSummary = (
  booking: ConfirmedBooking,
  cards?: PaymentCard[],
  addedGiftcards?: Giftcards | null,
  paymentHistory?: PaymentHistoryResponse,
): CheckoutSummary => {
  const { services, place, extra } = booking || {};
  const [service] = services || [];
  const employee = service.employee || {};
  const { giftcards, campaigns } = extra || {};
  const totalGiftcards = { ...giftcards, ...(addedGiftcards || {}) };

  const isFromPrice = shouldShowFrom(services, place);
  const isOnlinePaymentRequired = getIsOnlinePaymentRequired(employee, place, services);
  const anyEmployee = booking?.extra?.anyEmployee;
  const priceListId = (!anyEmployee && booking.services?.[0]?.employee?.about?.priceListId) || 0;
  const totalWithoutFee = getTotalPrice(
    booking.services,
    booking.place,
    priceListId,
    totalGiftcards || {},
    0,
    1,
    false,
    campaigns || [],
    false,
    getDynamicPriceListIdKey(booking),
  );

  const shouldShowPrices = showPrices(booking.place, booking.services);
  const canPayOnline = isTotalPayable(totalWithoutFee, shouldShowPrices);
  const isPayAtPlace = booking.extra.paymentMethod === 0;

  const acceptsGiftcard = Boolean(place?.about?.settings?.acceptsGiftCard) && canPayOnline;
  const acceptsWellnesscard = acceptsGiftcard && services.every((s) => s.service.about?.settings?.healthCareCard);

  const canPayWithKlarna = isPayAtPlace && canPayOnline && canPayKlarnaAfterBooking(booking);
  const canPayWithQliro = isPayAtPlace && canPayOnline && canPayQliroAfterBooking(booking);
  const canPayWithCardOnFile = isPayAtPlace && canPayOnline && canPayCardOnFileAfterBooking(booking);
  const canPayWithSwish = isPayAtPlace && canPayOnline && canPaySwishAfterBooking(booking);
  const canPayWithGooglePay = canPayWithCardOnFile && sessionStorage.getItem('canMakeGooglePayment') === 'true';
  const canPayWithApplePay = canPayWithCardOnFile && sessionStorage.getItem('canMakeApplePayment') === 'true';

  const options = [
    ...(canPayWithQliro ? [CHECKOUT_PAYMENT_METHOD.QLIRO] : []),
    ...(canPayWithApplePay ? [CHECKOUT_PAYMENT_METHOD.APPLE_PAY] : []),
    ...(canPayWithGooglePay ? [CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY] : []),
    ...(canPayWithCardOnFile ? [CHECKOUT_PAYMENT_METHOD.STORED_COF] : []),
    ...(canPayWithKlarna ? [CHECKOUT_PAYMENT_METHOD.KLARNA] : []),
    ...(canPayWithCardOnFile ? [CHECKOUT_PAYMENT_METHOD.NEW_COF] : []),
    ...(canPayWithSwish ? [CHECKOUT_PAYMENT_METHOD.SWISH] : []),
    ...(!canPayOnline ? [CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE] : []),
    CHECKOUT_PAYMENT_METHOD.NONE,
  ];

  const finalWithoutAddedGiftcards = +getTotalPrice(
    services,
    place,
    priceListId,
    giftcards || {},
    0,
    1,
    false,
    campaigns,
    false,
    getDynamicPriceListIdKey(booking),
  );

  let availablePaymentMethods = options.reduce<AvailablePaymentMethod[]>((acc, option) => {
    const total = getTotalPrice(
      services,
      place,
      priceListId,
      totalGiftcards || {},
      0,
      1,
      true,
      campaigns,
      false,
      getDynamicPriceListIdKey(booking),
    );

    const isPriceVariable = isFromPrice && total !== _s('variablePrice') && total !== _s('freePrice');

    const final = getTotalPrice(
      services,
      place,
      priceListId,
      totalGiftcards,
      0,
      1,
      false,
      campaigns,
      false,
      getDynamicPriceListIdKey(booking),
    );

    acc.push({
      type: option,
      total,
      final: +final,
      totalLabel: isPriceVariable ? _s('from') : _s('total'),
    });

    return acc;
  }, []);

  const forceNonAggressivePaymentFlow = Boolean(employee?.about?.settings?.forceNonAggressivePaymentFlow);

  let preSelectedPaymentMethod = ((): CheckoutSummary['preSelectedPaymentMethod'] => {
    if (forceNonAggressivePaymentFlow) return undefined;
    return getPreSelectedCheckoutMethodIfAny(availablePaymentMethods, cards);
  })();

  if (paymentHistory) {
    const personalisedData = getPersonalisedPaymentData(availablePaymentMethods, paymentHistory, cards);
    preSelectedPaymentMethod = personalisedData.preSelectedPaymentMethod
      ? { ...personalisedData.preSelectedPaymentMethod }
      : undefined;
    availablePaymentMethods = [...personalisedData.personalisedPaymentOptions];
  }

  return {
    acceptsGiftcard,
    canPayOnline,
    acceptsValuecard: false,
    finalWithoutGiftcards: finalWithoutAddedGiftcards,
    acceptsWellnesscard,
    availablePaymentMethods,
    isOnlinePaymentRequired,
    preSelectedPaymentMethod,
  };
};

export const getGiftcardCheckoutSummary = ({
  cards,
  orderTotal,
  discountAmount,
}: {
  cards?: PaymentCard[];
  orderTotal: number;
  discountAmount?: number;
}): CheckoutSummary & { price: number; discountAmount: number } => {
  const price = orderTotal - (discountAmount ? discountAmount / 100 : 0);

  const options = [CHECKOUT_PAYMENT_METHOD.KLARNA];

  const availablePaymentMethods = options.map((option) => ({
    type: option,
    total: price,
    final: price,
    totalLabel: _s('total'),
  }));

  const preSelectedPaymentMethod = getPreSelectedCheckoutMethodIfAny(availablePaymentMethods, cards);

  return {
    acceptsGiftcard: false,
    acceptsValuecard: false,
    acceptsWellnesscard: false,
    availablePaymentMethods,
    isOnlinePaymentRequired: true,
    preSelectedPaymentMethod,
    discountAmount: discountAmount ? discountAmount / 100 : 0,
    price,
    canPayOnline: true,
    finalWithoutGiftcards: price,
  };
};

export const getBookingCheckoutSummary = ({
  booking,
  employee,
  cards,
  selectedCapacity,
  emailRequired = true,
  isGuestCheckout = false,
  paymentHistory,
  forceRequireOnlinePayment = false, // temporary flag for sistaminuten experiment
}: {
  booking: BookState;
  employee: CheckoutEmployee;
  cards?: PaymentCard[];
  selectedCapacity?: number;
  emailRequired?: boolean;
  isGuestCheckout?: boolean;
  paymentHistory?: PaymentHistoryResponse;
  forceRequireOnlinePayment?: boolean;
}): BookingCheckoutSummary => {
  const { services, place, time, campaigns, usageReqId, appliedBundle } = booking || {};
  const { giftcards } = usageReqId || {};
  const shouldShowPrices = showPrices(place, services);
  const isFromPrice = shouldShowFrom(services, place);
  const isOnlinePaymentRequired =
    forceRequireOnlinePayment || getIsOnlinePaymentRequired(employee.actual, place, services);
  const capacity = time?.selectedCapacity || selectedCapacity || 1;
  const priceListId = employee.random ? 0 : employee?.actual?.about?.priceListId || '';
  const hasCancelTime = getEmployeeCancelMargin(employee.actual, place);
  const placeTz = getPlaceTimezone(booking.place);
  const cancelableUntilTs = getStartTime(time) - hasCancelTime * 60 * 1000;
  const canCancel = Boolean(
    hasCancelTime && bookingsAllowedToBeCancelled(employee.actual) && hasNotPasted(cancelableUntilTs, placeTz),
  );

  const finalPrice = getTotalPrice(
    services,
    place,
    priceListId,
    giftcards,
    0,
    capacity,
    false,
    campaigns,
    false,
    time,
    appliedBundle,
  );
  const canPayOnline = isTotalPayable(finalPrice, shouldShowPrices);

  const { acceptsValueCard: employeeAcceptsValuecard } = employee.actual?.about?.settings || {};
  const placeAcceptsGiftcard = Boolean(place?.about?.settings?.acceptsGiftCard);

  const acceptsGiftcard = placeAcceptsGiftcard && canPayOnline;
  const acceptsValuecard = placeAcceptsGiftcard && canPayOnline && employeeAcceptsValuecard;
  const acceptsWellnesscard =
    placeAcceptsGiftcard && canPayOnline && services.every((s) => s.about?.settings?.healthCareCard);

  const hasPayAtPlace = !isOnlinePaymentRequired;
  const hasQliro = canPayQliro(employee.actual, time, shouldShowPrices);
  const hasKlarna = canPayKlarna(employee.actual, time, shouldShowPrices);
  const hasSwish = canPaySwish(employee.actual, time, shouldShowPrices, finalPrice);
  const hasCoF = canPayCardOnFile(employee.actual, time, shouldShowPrices, finalPrice, isOnlinePaymentRequired);
  const payLater = !hasSwish && !isOnlinePaymentRequired && !isGuestCheckout;

  const finalWithoutGiftcards = +getTotalPrice(
    services,
    place,
    priceListId,
    {},
    0,
    capacity,
    false,
    campaigns,
    false,
    time,
    appliedBundle,
  );

  const hasGooglePay = hasCoF && sessionStorage.getItem('canMakeGooglePayment') === 'true';
  const hasApplePay = hasCoF && sessionStorage.getItem('canMakeApplePayment') === 'true';

  const forceNonAggressivePaymentFlow = Boolean(employee?.actual?.about?.settings?.forceNonAggressivePaymentFlow);

  /**
   * the order of the payment methods is the order they are going to show in the UI
   */
  const options: AvailablePaymentMethod['type'][] = [
    ...(hasQliro ? [CHECKOUT_PAYMENT_METHOD.QLIRO] : []),
    ...(hasApplePay && emailRequired ? [CHECKOUT_PAYMENT_METHOD.APPLE_PAY] : []),
    ...(hasGooglePay && emailRequired ? [CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY] : []),
    ...(hasCoF && emailRequired ? [CHECKOUT_PAYMENT_METHOD.STORED_COF] : []),
    ...(hasSwish && emailRequired ? [CHECKOUT_PAYMENT_METHOD.SWISH] : []),
    ...(hasPayAtPlace || !canPayOnline ? [CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE] : []),
    ...(hasKlarna ? [CHECKOUT_PAYMENT_METHOD.KLARNA] : []),
    ...(hasCoF && emailRequired ? [CHECKOUT_PAYMENT_METHOD.NEW_COF] : []),
    CHECKOUT_PAYMENT_METHOD.NONE,
  ];

  /*
   * if for some reason no online method is supported and isOnlinePaymentRequired setting is true add pay at place
   * as a payment method so the user can still book
   */
  if (options.length === 1 && options[0] === CHECKOUT_PAYMENT_METHOD.NONE) {
    options.push(CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE);
  }

  let availablePaymentMethods = options.reduce<AvailablePaymentMethod[]>((acc, option) => {
    const totalPrice = getTotalPrice(
      services,
      place,
      priceListId,
      giftcards || {},
      0,
      capacity,
      true,
      campaigns,
      false,
      time,
      appliedBundle,
    );

    const isPriceVariable = isFromPrice && totalPrice !== _s('variablePrice') && totalPrice !== _s('freePrice');

    /**
     * don't dare to change the totalPrice helper so for now since we still have old checkout
     * so if the price is an empty string we show variablePrice locale instead
     */
    const total = totalPrice === '' ? _s('variablePrice') : totalPrice;

    acc.push({
      type: option,
      total,
      final: +finalPrice,
      totalLabel: isPriceVariable ? _s('from') : _s('total'),
    });

    return acc;
  }, []);

  let preSelectedPaymentMethod = ((): CheckoutSummary['preSelectedPaymentMethod'] => {
    if (forceNonAggressivePaymentFlow) return undefined;
    return getPreSelectedCheckoutMethodIfAny(availablePaymentMethods, cards);
  })();

  if (paymentHistory) {
    const personalisedData = getPersonalisedPaymentData(availablePaymentMethods, paymentHistory, cards);
    preSelectedPaymentMethod = personalisedData.preSelectedPaymentMethod
      ? { ...personalisedData.preSelectedPaymentMethod }
      : undefined;
    availablePaymentMethods = [...personalisedData.personalisedPaymentOptions];
  }

  return {
    canCancel,
    cancelableUntilTs,
    canPayOnline,
    acceptsGiftcard,
    acceptsValuecard,
    acceptsWellnesscard,
    isOnlinePaymentRequired,
    availablePaymentMethods,
    preSelectedPaymentMethod,
    payLater,
    finalWithoutGiftcards,
    finalPrice: +finalPrice,
  };
};

export const getBundleCheckoutSummary = ({
  bundleState,
  cards,
  paymentHistory,
}: {
  bundleState: BundleState;
  cards?: PaymentCard[];
  paymentHistory?: PaymentHistoryResponse;
}): CheckoutSummary => {
  const { bundle } = bundleState;
  const hasGooglePay = sessionStorage.getItem('canMakeGooglePayment') === 'true';
  const hasApplePay = sessionStorage.getItem('canMakeApplePayment') === 'true';

  const options = [
    CHECKOUT_PAYMENT_METHOD.QLIRO,
    ...(hasApplePay ? [CHECKOUT_PAYMENT_METHOD.APPLE_PAY] : []),
    ...(hasGooglePay ? [CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY] : []),
    CHECKOUT_PAYMENT_METHOD.STORED_COF,
    CHECKOUT_PAYMENT_METHOD.SWISH,
    CHECKOUT_PAYMENT_METHOD.NEW_COF,
    CHECKOUT_PAYMENT_METHOD.NONE,
  ];

  let availablePaymentMethods = options.reduce<AvailablePaymentMethod[]>((acc, option) => {
    acc.push({
      type: option,
      total: `${bundle.price}`,
      final: bundle.finalPrice ?? bundle.price,
      totalLabel: _s('total'),
    });

    return acc;
  }, []);

  let preSelectedPaymentMethod = undefined;

  if (paymentHistory) {
    const personalisedData = getPersonalisedPaymentData(availablePaymentMethods, paymentHistory, cards);
    preSelectedPaymentMethod = personalisedData.preSelectedPaymentMethod
      ? { ...personalisedData.preSelectedPaymentMethod }
      : undefined;
    availablePaymentMethods = [...personalisedData.personalisedPaymentOptions];
  }

  return {
    availablePaymentMethods,
    preSelectedPaymentMethod,
    acceptsGiftcard: false,
    acceptsValuecard: false,
    acceptsWellnesscard: false,
    isOnlinePaymentRequired: true,
  };
};

export function isHidden(paymentOption: SelectedPaymentMethod | CouponMethod | UnselectedPaymentMethod): boolean {
  return paymentOption.hidden ?? false;
}

export function isHighlighted(
  paymentOption: (SelectedPaymentMethod | CouponMethod | UnselectedPaymentMethod)['type'],
): boolean {
  /**
   * no logic currently, maybe we will use some flag for this later.
   * for now we just hardcode this to false
   */

  // @TODO: hardcoded for now
  if (paymentOption === CHECKOUT_PAYMENT_METHOD.QLIRO) return true;

  return false;
}

export function hasPaymentMethod(summary: CheckoutSummary, toCheck: CheckoutPaymentMethod): boolean {
  return summary.availablePaymentMethods.some((method) => method.type === toCheck);
}

function shouldShowInOptions(
  paymentOption: SelectedPaymentMethod | CouponMethod | UnselectedPaymentMethod,
  cards?: PaymentCard[],
): boolean {
  if (paymentOption.type === CHECKOUT_PAYMENT_METHOD.SWISH && paymentOption.final > MINIMUM_SWISH_AMOUNT_THRESHOLD) {
    return false;
  }

  if (paymentOption.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
    return cards?.length > 0;
  }

  return true;
}

export function getPersonalisedPaymentData(
  paymentOptions: SelectedPaymentMethod[],
  paymentHistory: PaymentHistoryResponse,
  cards?: PaymentCard[],
): {
  preSelectedPaymentMethod: BookingCheckoutSummary['preSelectedPaymentMethod'];
  personalisedPaymentOptions: SelectedPaymentMethod[];
} {
  let personalisedPaymentOptions: SelectedPaymentMethod[] = [];
  const popular = paymentHistory.popularOrder;
  const paymentOptionsObject: { [key in SelectedPaymentMethod['type']]?: SelectedPaymentMethod } = {};

  paymentOptions.forEach((item) => {
    item.hidden = true;
    paymentOptionsObject[item.type] = item;
  });
  const lastAtPlace = paymentHistory.previousPaymentMethod;
  let addedOptions: CheckoutPaymentMethod[] = [];
  // we have a previous online payment method at this place
  if (
    lastAtPlace &&
    paymentOptionsObject[lastAtPlace.paymentMethod] &&
    shouldShowInOptions(paymentOptionsObject[lastAtPlace.paymentMethod], cards)
  ) {
    const item = paymentOptionsObject[lastAtPlace.paymentMethod];
    personalisedPaymentOptions.push(item);
    addedOptions.push(lastAtPlace.paymentMethod);
  } else {
    // check last 5 methods used by user
    const lastUsed = paymentHistory.history ?? [];
    for (let i = 0; i < lastUsed.length; i++) {
      const item = paymentOptionsObject[lastUsed[i].paymentMethod];
      if (item && shouldShowInOptions(item, cards)) {
        personalisedPaymentOptions.push(item);
        addedOptions.push(lastUsed[i].paymentMethod);
        // last used is an online payment method && is in a tie with another method, add also that one
        if (i === 0 && popular.indexOf(lastUsed[i].paymentMethod) !== -1) {
          if (
            lastUsed[1] &&
            lastUsed[0].count === lastUsed[1].count &&
            paymentOptionsObject[lastUsed[1].paymentMethod] &&
            shouldShowInOptions(paymentOptionsObject[lastUsed[1].paymentMethod], cards)
          ) {
            personalisedPaymentOptions.push(paymentOptionsObject[lastUsed[1].paymentMethod]);
            addedOptions.push(lastUsed[1].paymentMethod);
          }
        }
        break;
      }
    }
  }

  // no online history, add pay at place if exists
  if (addedOptions.length === 0 && paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE]) {
    personalisedPaymentOptions.push(paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE]);
    addedOptions.push(CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE);
  }

  // add up to 3 online payment methods
  popular.forEach((item) => {
    if (
      paymentOptionsObject[item] &&
      shouldShowInOptions(paymentOptionsObject[item], cards) &&
      personalisedPaymentOptions.length < 3 &&
      addedOptions.indexOf(item) === -1
    ) {
      personalisedPaymentOptions.push(paymentOptionsObject[item]);
      addedOptions.push(item);
    }
  });

  // if qliro / klarna exist and are not in the first 3 online payment methods, force them there
  if (
    addedOptions.indexOf(CHECKOUT_PAYMENT_METHOD.QLIRO) === -1 &&
    paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.QLIRO]
  ) {
    personalisedPaymentOptions.splice(2, 1, paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.QLIRO]);
    addedOptions.push(CHECKOUT_PAYMENT_METHOD.QLIRO);
  } else {
    if (
      addedOptions.indexOf(CHECKOUT_PAYMENT_METHOD.KLARNA) === -1 &&
      paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.KLARNA]
    ) {
      personalisedPaymentOptions.splice(2, 1, paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.KLARNA]);
      addedOptions.push(CHECKOUT_PAYMENT_METHOD.KLARNA);
    }
  }

  const newCardOption = paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.NEW_COF];
  let shouldPreselectOffline = false;

  // // if offline method is first
  if (personalisedPaymentOptions[0]?.type === CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE) {
    // if online payment methods available do not preselect offline
    if (personalisedPaymentOptions.length > 1) {
      // do not put qliro first ever
      const isNotQliroOptionSecond = personalisedPaymentOptions[1]?.type !== CHECKOUT_PAYMENT_METHOD.QLIRO;
      if (isNotQliroOptionSecond) {
        personalisedPaymentOptions[0] = personalisedPaymentOptions[1];
        personalisedPaymentOptions[1] = paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE];
      }
    } else {
      // if no online payment method check to see if NewCard and add it
      if (newCardOption) {
        personalisedPaymentOptions.push(newCardOption);
      } else {
        shouldPreselectOffline = true;
      }
    }
  }

  // never place qliro method as first
  if (personalisedPaymentOptions[0]?.type === CHECKOUT_PAYMENT_METHOD.QLIRO) {
    // switch between first and second if exists
    if (personalisedPaymentOptions.length > 1) {
      personalisedPaymentOptions[0] = personalisedPaymentOptions[1];
      personalisedPaymentOptions[1] = paymentOptionsObject[CHECKOUT_PAYMENT_METHOD.QLIRO];
    } else {
      // if no other methods and new card is available add new card first
      if (newCardOption) {
        personalisedPaymentOptions.unshift(newCardOption);
      }
    }
  }

  // if klarna method is first and no other methods
  // and new card is available add new card after
  if (
    personalisedPaymentOptions[0]?.type === CHECKOUT_PAYMENT_METHOD.KLARNA &&
    personalisedPaymentOptions.length === 1 &&
    newCardOption
  ) {
    personalisedPaymentOptions.push(newCardOption);
  }

  if (
    personalisedPaymentOptions.length === 2 &&
    (addedOptions.includes(CHECKOUT_PAYMENT_METHOD.QLIRO) || addedOptions.includes(CHECKOUT_PAYMENT_METHOD.KLARNA)) &&
    addedOptions.includes(CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE) &&
    newCardOption
  ) {
    // if no other methods and new card is available add new card first
    personalisedPaymentOptions.unshift(newCardOption);
  }

  personalisedPaymentOptions = [
    ...personalisedPaymentOptions.map((item) => {
      item.hidden = false;

      if (item.type === CHECKOUT_PAYMENT_METHOD.STORED_COF && cards.length > 0) {
        return {
          ...item,
          brand: cards?.[0]?.brand,
          lastFour: cards?.[0]?.lastFour,
          id: cards?.[0]?.id,
        };
      }

      return item;
    }),
    ...paymentOptions,
  ].filter((v, i, a) => a.findIndex((v2) => v2.type === v.type) === i);

  const preSelectedPaymentMethod = ((): CheckoutSummary['preSelectedPaymentMethod'] => {
    const validPreselectMethod = availablePrePaymentRadioButtonsMethodSchema.safeParse(personalisedPaymentOptions[0]);

    if (!validPreselectMethod.success) {
      return undefined;
    }

    if (validPreselectMethod.data.type === CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE && !shouldPreselectOffline) {
      return undefined;
    }

    if (!shouldBePreSelected(validPreselectMethod.data, personalisedPaymentOptions, cards)) {
      return undefined;
    }

    if (validPreselectMethod.data.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
      return {
        ...validPreselectMethod.data,
        brand: cards?.[0]?.brand,
        lastFour: cards?.[0]?.lastFour,
        id: cards?.[0]?.id,
      };
    }

    return validPreselectMethod.data;
  })();

  return { preSelectedPaymentMethod, personalisedPaymentOptions };
}

export const getBookingCheckoutEmployee = (locationState: any, booking: BookState): CheckoutEmployee => {
  const findEmployeeById = (employeId: number, employees: PlaceEmployee[]): PlaceEmployee =>
    employees.find((employee) => employee.id === employeId) || {};

  const selectedEmployeeId = locationState?.employee?.id ?? booking.employee;
  const actualEmployee = booking.place ? findEmployeeById(selectedEmployeeId, booking.place.employees) : {};
  const showSelectedEmployee = Boolean(booking.place?.about?.book?.showSelectedEmployee);
  const useRandomEmployee = Boolean(!booking.employee && !showSelectedEmployee);
  const shownEmployee = useRandomEmployee ? { id: 0, about: { name: _s('anyEmployee'), avatar: '' } } : actualEmployee;
  const terms = Boolean(actualEmployee?.about?.terms)
    ? actualEmployee.about.terms
    : booking.place?.about?.settings?.terms;

  return {
    random: useRandomEmployee,
    anyEmployeeSelected: !booking.employee,
    actual: actualEmployee,
    shown: shownEmployee,
    selectedEmployeeId: selectedEmployeeId,
    showSelected: showSelectedEmployee,
    terms,
  };
};

export const formattedDateTimeFromTimestamp = (timestamp: number): string =>
  moment(timestamp)
    .locale('sv')
    .utc()
    .format('ddd D MMM YYYY, [kl] HH:mm')
    .replace(/^\w+/, (day) => day.charAt(0).toUpperCase() + day.slice(1))
    .replace(/\b\w{3}\b/, (month) => month.charAt(0).toUpperCase() + month.slice(1));

export const getCheckoutID = (booking: BookState, employee: PlaceEmployee): string | undefined => {
  if (isServer || !booking.services || !booking.place || !booking.time) return undefined;
  const serviceIds = booking.services?.map((service) => service?.id).join('-') || '';
  const base64String = btoa(
    booking?.place?.id + '-' + employee.id + '-' + serviceIds + '-' + getStartTime(booking.time),
  );
  return base64String;
};

export const mapBookStateToCheckoutDetailsServices = (
  booking: BookState,
  employee: PlaceEmployee,
): CheckoutDetailsService[] => {
  const { place, campaigns, time, appliedBundle } = booking;
  const priceListId = employee?.about?.priceListId || '';
  const selectedCapacity = booking.time?.selectedCapacity;

  const services = booking.services.map((service) => {
    if (appliedBundle && appliedBundle.service.serviceId === service.id) {
      return {
        name: service.name,
        price: '0 kr',
        duration: _s('bundleCheckoutUsageCount', {
          usages: appliedBundle.service.usages + 1,
          quantity: appliedBundle.service.quantity,
        }),
      };
    }

    const name = `${selectedCapacity > 1 ? selectedCapacity + ' x ' : ''}${service.name}`;
    const price = getServicePrice(service, priceListId, place, undefined, time);
    const duration = getServiceDuration(service, priceListId, 1);
    const { campaignService } = getServiceCampaignIfAny(service, campaigns);

    const offerPrice = getServicePrice(service, priceListId, place, campaignService, time);
    const discount = (() => {
      if (offerPrice === price) return null;
      return getOfferPrice(price, campaignService);
    })();

    return {
      name,
      price,
      duration,
      discount: discount ? `${discount} kr` : null,
    };
  });

  return services;
};

export const getApplePaySummaryServiceItems = (
  bookingInfoServices: CheckoutDetailsService[],
  payLater: boolean,
): ApplePayJS.ApplePayLineItem[] => {
  return bookingInfoServices.reduce(
    (acc: ApplePayJS.ApplePayLineItem[], service: CheckoutDetailsService): ApplePayJS.ApplePayLineItem[] => {
      const priceLabel = service.price.replace(/( kr|\s)/g, '').replace(_s('from'), '');
      const amount = `${Number(priceLabel).toFixed(2)}`;
      acc.push({ label: service.name, amount, type: payLater ? 'pending' : 'final' });

      if (!service.discount) return acc;

      const discountLabel = service.discount.replace(' kr', '').replace(/\s/g, '');
      const discount = `-${(+amount - +discountLabel).toFixed(2)}`;
      acc.push({
        label: `${_s('campaignOffer')} ${service.name}`,
        amount: discount,
        type: 'final',
      });
      return acc;
    },
    [],
  );
};

export const getApplePaySummaryGiftcardItems = (
  booking: BookState,
  payLater: boolean,
): ApplePayJS.ApplePayLineItem[] => {
  return Object.values(booking?.usageReqId?.giftcards || []).map((giftcard) => {
    return {
      label: getGiftcardTypeLabel(giftcard) + ' ' + giftcard.code,
      amount: `-${(giftcard.amount / 100).toFixed(2)}`,
      type: payLater ? 'pending' : 'final',
    };
  });
};

export const getGooglePaySummaryServiceItems = (
  bookingInfoServices: CheckoutDetailsService[],
  payLater: boolean,
): google.payments.api.DisplayItem[] => {
  return bookingInfoServices.reduce(
    (acc: google.payments.api.DisplayItem[], service, idx): google.payments.api.DisplayItem[] => {
      const priceLabel = service.price.replace(/( kr|\s)/g, '').replace(_s('from'), '');
      const price = `${(+priceLabel).toFixed(2)}`;
      acc.push({ label: service.name, type: 'LINE_ITEM', price, status: payLater ? 'PENDING' : 'FINAL' });

      if (!service.discount) return acc;

      const discountLabel = service.discount.replace(' kr', '').replace(/\s/g, '');
      const discount = `-${(+price - +discountLabel).toFixed(2)}`;
      acc.push({
        label: `${_s('campaignOffer')} ${service.name}`,
        type: 'DISCOUNT',
        price: discount,
        status: payLater ? 'PENDING' : 'FINAL',
      });

      return acc;
    },
    [],
  );
};

export const getGooglePaySummaryGiftcardItems = (
  booking: BookState,
  payLater: boolean,
): google.payments.api.DisplayItem[] => {
  return Object.values(booking?.usageReqId?.giftcards || []).map((giftcard) => {
    return {
      label: getGiftcardTypeLabel(giftcard) + ' ' + giftcard.code,
      price: `-${(giftcard.amount / 100).toFixed(2)}`,
      type: 'DISCOUNT',
      status: payLater ? 'PENDING' : 'FINAL',
    };
  });
};

export const getGiftcardTypeLabel = (giftcard: Giftcard): string => {
  switch (giftcard?.giftCardType) {
    case 'UGC':
    case 'IGC':
    case 'EGC':
      return _s('giftcardLabel');
    case 'VCIGC':
    case 'VCEGC':
      return _s('valuecardLabel');
    case 'UGCW':
      return _s('wellnessCardsLabel');
    default:
      return _s('giftcardLabel');
  }
};

export const mapBookingCheckoutToCheckoutDetailsProps = (
  summary: BookingCheckoutSummary,
  selectedPaymentMethod: SelectedPaymentMethod,
  employee: PlaceEmployee,
  booking: BookState,
  isGuestCheckout?: boolean,
): CheckoutDetailsProps => {
  const startingTime = getStartTime(booking.time);
  const dateTime = formattedDateTimeFromTimestamp(startingTime);
  const { availablePaymentMethods } = summary;
  const hasSwish = availablePaymentMethods.find(
    (paymentMethod) => paymentMethod.type === CHECKOUT_PAYMENT_METHOD.SWISH,
  );

  const { total, totalLabel } = summary.availablePaymentMethods.find(
    (method) => method.type === selectedPaymentMethod.type,
  );

  const priceToPay = { price: `${total}`, label: totalLabel };
  const formattedCancelTime = formattedDateTimeFromTimestamp(summary.cancelableUntilTs);

  const giftcards = Object.values(booking?.usageReqId?.giftcards || []).map((giftcard) => {
    return {
      amount: `-${giftcard.amount / 100} kr`,
      code: giftcard.code,
      label: getGiftcardTypeLabel(giftcard),
      giftcardExpiryDate: moment(giftcard.giftCardExpiryDate).format('YYYY-MM-DD'),
    };
  });

  const payLater =
    !hasSwish &&
    !isGuestCheckout &&
    summary.canPayOnline &&
    !summary.isOnlinePaymentRequired &&
    (selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY ||
      selectedPaymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF);

  const mapped: CheckoutDetailsProps = {
    information: summary.canCancel ? _s('cancelBefore', { time: formattedCancelTime }) : '',
    dateTime,
    priceToPay,
    performer: employee.about.name,
    placeName: booking.place.about.name,
    logo: booking.place.about.profileImage,
    giftcards: giftcards,
    payLater,
  };
  return mapped;
};

export const mapBundleStateToCheckoutDetailsProps = (bundle: BundleState): CheckoutDetailsProps => {
  return {
    dateTime: '',
    information: '',
    performer: _s('anyEmployee'),
    placeName: bundle.place.about.name,
    logo: bundle.place.about.profileImage,
    giftcards: [],
    priceToPay: { price: bundle.bundle.finalPriceLabel ?? bundle.bundle.priceLabel, label: _s('total') },
    bundles: mapBundleStateToCheckoutDetailsBundles(bundle),
  };
};

const mapBundleStateToCheckoutDetailsBundles = (bundle: BundleState): CheckoutDetailsBundle[] => {
  const bundles = ((): CheckoutDetailsBundle[] => {
    return [
      {
        name: bundle.bundle.name,
        price: bundle.bundle.priceLabel,
        discount: bundle.bundle.finalPriceLabel ? bundle.bundle.finalPriceLabel : null,
        quantity: `(${_s('buyBundles.quantity', { count: bundle.bundle.service.quantity })})`,
        validity: _s('buyBundles.valid', { validity: _s('months', { count: bundle.bundle.validMonths }) }),
      },
    ];
  })();

  return bundles;
};

export const getBookingRequestBody = ({
  booking,
  employee,
  formfields,
  guestId,
  summary,
}: {
  booking: BookState;
  employee: CheckoutEmployee;
  formfields: CheckoutFormData;
  guestId?: string;
  summary: BookingCheckoutSummary;
}): Omit<SaveBookingRequest, 'paymentMethod'> => {
  const campaigns = booking?.campaigns?.map((campaign) => campaign.id).join('-') || undefined;
  const availableGiftcardMethods: SaveBookingRequest['paymentMethods'] = [];

  const observation = (() => {
    /**
     * if client books for someone else add the observation field for m3
     */
    if (formfields[1][GUEST_FORM_FIELD_ID]) {
      return `Gästbokning för: ${formfields[1][GUEST_FORM_FIELD_ID]}`;
    }
    return '';
  })();

  if (summary.acceptsGiftcard) availableGiftcardMethods.push('Giftcard');
  if (summary.acceptsValuecard) availableGiftcardMethods.push('Valuecard');
  if (summary.acceptsWellnesscard) availableGiftcardMethods.push('Wellnesscard');

  const paymentMethods = summary.availablePaymentMethods
    .reduce((acc: SaveBookingRequest['paymentMethods'], method) => {
      if (method.type === CHECKOUT_PAYMENT_METHOD.NONE) {
        return acc;
      }

      if (method.type === CHECKOUT_PAYMENT_METHOD.NEW_COF || method.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
        acc.push(CHECKOUT_PAYMENT_METHOD.STORED_COF);
        return acc;
      }

      acc.push(method.type);
      return acc;
    }, [])
    .concat(availableGiftcardMethods);

  let queryParameters = undefined;
  const gclid = sessionStorage.getItem('gclid');
  if (gclid) {
    queryParameters = {};
    queryParameters.gclid = gclid;
  }

  return {
    observation,
    place: booking.place.id,
    service: booking.services.map((service) => service.id).join('-'),
    isMPBooking: isMpBooking(),
    bookingInitiator: getBookingInitiator(),
    time: booking.time,
    discount: false,
    bidId: !isServer ? getCookie('clickedTopPlaces_' + booking.place.id) : undefined,
    random: employee.random,
    anyEmployeeSelected: employee.anyEmployeeSelected,
    employee: employee.selectedEmployeeId,
    capacity: booking.time?.selectedCapacity || Object.keys(formfields).length || 1,
    formfields,
    giftcards: Object.keys(booking.usageReqId.giftcards).map((code) => booking.usageReqId.giftcards[code]),
    paymentMethods,
    defaultPaymentMethod: summary.preSelectedPaymentMethod?.type,
    campaigns,
    guestId,
    bookingBundleId: booking.appliedBundle?.id,
    queryParameters,
  };
};

export const getBookingRetryPathname = (booking: BookState) => {
  const slug = booking.place.about.slug ? booking.place.about.slug + '-' : '';
  const placeId = booking.place.id;
  const serviceSlug = booking.services[0].about.slug ? booking.services[0].about.slug + '-' : '';

  return `/boka-tjanst/${slug}${placeId}/${serviceSlug}${booking.services[0].id}`;
};

export const isPreSelectedPaymentMethod = (method: AvailablePaymentMethod): method is AvailablePrePaymentMethod => {
  switch (method.type) {
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.SWISH:
      return true;
    case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
    case CHECKOUT_PAYMENT_METHOD.NEW_COF:
    case CHECKOUT_PAYMENT_METHOD.NONE:
      return false;
    default:
      const never: never = method;
      return never;
  }
};

const PRE_SELECTED_METHOD_DEFAULT_ORDER: AvailablePrePaymentMethod['type'][] = [
  CHECKOUT_PAYMENT_METHOD.QLIRO,
  CHECKOUT_PAYMENT_METHOD.APPLE_PAY,
  CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY,
  CHECKOUT_PAYMENT_METHOD.STORED_COF,
  CHECKOUT_PAYMENT_METHOD.SWISH,
  CHECKOUT_PAYMENT_METHOD.KLARNA,
];

export const sortPreSelectedMethod = (
  a: AvailablePrePaymentMethod['type'],
  b: AvailablePrePaymentMethod['type'],
): number => {
  return PRE_SELECTED_METHOD_DEFAULT_ORDER.indexOf(a) - PRE_SELECTED_METHOD_DEFAULT_ORDER.indexOf(b);
};

const shouldBePreSelected = (
  paymentMethod: AvailablePaymentMethod,
  availablePaymentMethods: AvailablePaymentMethod[],
  cards?: PaymentCard[],
): boolean => {
  const includesApplePay = availablePaymentMethods.some((method) => method.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY);

  const isAppleDevice = ((isMacOs || isIOS) && isSafari) || (isIOS && (+osVersion?.split('.')?.[0] || 0 >= 16));

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
    return cards?.length > 0;
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY && includesApplePay && isAppleDevice) {
    return false;
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.SWISH && paymentMethod.final > MINIMUM_SWISH_AMOUNT_THRESHOLD) {
    return false;
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.QLIRO) {
    return false;
  }

  return true;
};

export const getPreSelectedCheckoutMethodIfAny = (
  paymentMethods: AvailablePaymentMethod[],
  cards?: PaymentCard[],
): CheckoutSummary['preSelectedPaymentMethod'] => {
  const filtered = paymentMethods.filter((method) => method.type !== CHECKOUT_PAYMENT_METHOD.NONE);
  const singlePaymentOption = filtered.length === 1;

  // if only pay at place is available, return it
  if (singlePaymentOption && filtered[0].type === CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE) {
    return filtered[0];
  }

  const availablePreSelectedMethods = paymentMethods
    .filter(isPreSelectedPaymentMethod)
    .sort((a, b) => sortPreSelectedMethod(a.type, b.type));

  const includesApplePay = availablePreSelectedMethods.some(
    (method) => method.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY,
  );
  const isAppleDevice = ((isMacOs || isIOS) && isSafari) || (isIOS && (+osVersion?.split('.')?.[0] || 0 >= 16));

  if (availablePreSelectedMethods.length) {
    const method = availablePreSelectedMethods.find((method) => {
      if (method.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
        return cards?.length > 0;
      }
      if (method.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY && includesApplePay && isAppleDevice) {
        return false;
      }
      if (method.type === CHECKOUT_PAYMENT_METHOD.SWISH && method.final > MINIMUM_SWISH_AMOUNT_THRESHOLD) {
        return false;
      }
      return method;
    });

    // if we have a stored card we need to add card details to the selectedPaymentMethod
    if (method?.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
      return { ...method, brand: cards?.[0]?.brand, lastFour: cards?.[0]?.lastFour, id: cards?.[0]?.id };
    }
    return method;
  }

  return undefined;
};

export const getUpdateEditProfileFields = (formData: CheckoutFormData['1']): UpdateUserInfoFields => {
  const editUserFields = Object.keys(formData)
    .filter((key) => !PROFILE_FORM_FIELD_ID[key])
    .reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});

  return editUserFields;
};

export const getUserProfileInfoFields = (user: any): { id: string; value: string }[] =>
  Object.entries({
    [PROFILE_FORM_FIELD_ID.GIVEN_NAME]: user?.about?.givenName ?? '',
    [PROFILE_FORM_FIELD_ID.FAMILY_NAME]: user?.about?.familyName ?? '',
    [PROFILE_FORM_FIELD_ID.EMAIL]: user?.contact?.email ?? '',
    [PROFILE_FORM_FIELD_ID.MOBILE]: (user?.contact?.mobile || user.contact.phone) ?? '',
    [PROFILE_FORM_FIELD_ID.LOCALITY]: user?.contact?.locality ?? '',
    [PROFILE_FORM_FIELD_ID.POSTAL_CODE]: user?.contact?.postalCode ?? '',
    [PROFILE_FORM_FIELD_ID.STREET_ADDRESS]: user?.contact?.streetAddress ?? '',
  })
    .map(([id, value]) => ({ id, value }))
    .filter(({ value }) => value);

export type BuildBookingTrackingProps = {
  booking: BookState;
  employee: CheckoutEmployee;
  summary: BookingCheckoutSummary;
  selectedPaymentMethod: SelectedPaymentMethod;
  formFields: FormField[];
  id?: number;
  selectedCapacity?: number;
};

export const buildBookingTrackingProps = ({
  booking,
  employee,
  formFields,
  selectedPaymentMethod,
  summary,
  selectedCapacity,
}: BuildBookingTrackingProps): CheckoutTracking.Booking => {
  try {
    const { time, place, services, usageReqId, campaigns } = booking;
    const shouldShowPrices = showPrices(place, services);
    const { canPayOnline, availablePaymentMethods } = summary;
    const sumToPay = availablePaymentMethods.find((p) => p.type === selectedPaymentMethod.type).final;
    const capacity = time?.selectedCapacity || selectedCapacity || 1;
    const priceListId = employee.random ? 0 : employee?.actual?.about?.priceListId || '';
    const serviceCampaigns = getServiceCampaigns(services, campaigns);

    const bookingTotal = canPayOnline || sumToPay === 0 ? sumToPay : undefined;

    const bookingProps = {
      hidden_amount: !shouldShowPrices,
      booking_total: bookingTotal,
      days_until_booking: getDaysUntil(getStartTime(time)),
      number_of_services: services.length * capacity,
      company_id: place.id,
      company_type: getCompanyType(place),
      booking_starting_point: getBookingStartingPoint(),
    };

    const extraInfoStep = formFields.length > 0;

    const extraProps = {
      extra_info_step: extraInfoStep,
      notes_to_merchant: formFields.some((field) => field.id === DEFAULT_FORM_FIELD_ID.BOOKING_NOTES),
      gift_card: usageReqId && usageReqId.giftcards && Object.keys(usageReqId.giftcards).length > 0,
      campaign: campaigns.length > 0,
    };

    const { items, total } = getTrackingPrices(place, services, priceListId, capacity, serviceCampaigns, 0, time);

    const transactionProps = {
      allowMarketing: allowMarketing(services),
      value: bookingTotal,
      hiddenValue: total,
      currency: 'SEK',
      numItems: services.length * capacity,
      contentIds: services.map((service) => service.id),
      contentName: services.map((service) => service.name).join(', '),
      contentType: 'product',
      description: services.map((service) => service.name).join(', '),
      items,
      place: {
        id: place.id,
        name: place.about.name,
        about: null,
      },
    };

    if (
      place.about &&
      ((place.about.googleAnalytics4Tracking && place.about.googleAnalytics4Tracking.length > 0) ||
        (place.about.googleAnalyticsTracking && place.about.googleAnalyticsTracking.length > 0) ||
        (place.about.facebookPixelTracking && place.about.facebookPixelTracking.length > 0))
    ) {
      transactionProps.place.about = {
        googleAnalyticsTracking: place.about.googleAnalyticsTracking,
        googleAnalytics4Tracking: place.about.googleAnalytics4Tracking,
        facebookPixelTracking: place.about.facebookPixelTracking,
      };
    }

    const giftcards = Object.values(usageReqId?.giftcards || {}).map((giftcard) => giftcard);
    const appliedBundle = booking.appliedBundle;

    return {
      bookingProps,
      extraProps,
      transactionProps,
      paymentMethodsProps: getPaymentMethodsTracking({ selectedPaymentMethod, summary, giftcards, appliedBundle }),
    };
  } catch (error) {
    return {};
  }
};

export const saveBookingTrackingProps = (props: CheckoutTracking.Booking) => {
  bookActions.saveTrackingProps(props);
};

export const savePaymentTrackingProps = (props: CheckoutTracking.PaymentMethods) => {
  bookActions.saveTrackingProps(props);
};

export const bundleCheckoutTrackingManager = () => {
  const CHECKOUT_TRACKING_KEY = 'bookTrk'; // <- @TODO: temporary until we fix the tracking properly

  const saveTrackingProps = (trackingProps: CheckoutTracking.Bundle) => {
    if (!isServer) sessionStorage.setItem(CHECKOUT_TRACKING_KEY, JSON.stringify(trackingProps));
  };

  const getTrackingProps = (): CheckoutTracking.Bundle => {
    if (isServer) return {};
    return JSON.parse(sessionStorage.getItem(CHECKOUT_TRACKING_KEY)) || {};
  };

  const clearTrackingProps = () => {
    if (!isServer) sessionStorage.removeItem(CHECKOUT_TRACKING_KEY);
  };

  const buildTrackingProps = (
    bundle: BundleState,
    summary: CheckoutSummary,
    selectedPaymentMethod: SelectedPaymentMethod,
  ): CheckoutTracking.Bundle => {
    const { availablePaymentMethods } = summary;
    const sumToPay = availablePaymentMethods.find((p) => p.type === selectedPaymentMethod.type).final;

    const bundleProps = {
      bundle_total: sumToPay,
      company_id: bundle.place.id,
    };

    const extraProps = {
      campaign: Boolean(bundle.bundle.finalPriceLabel),
    };

    return {
      bundleProps,
      extraProps,
      paymentMethodsProps: getPaymentMethodsTracking({ selectedPaymentMethod, summary }),
    };
  };

  return {
    saveTrackingProps,
    getTrackingProps,
    clearTrackingProps,
    buildTrackingProps,
  };
};

export const getPaymentMethodsTracking = ({
  selectedPaymentMethod,
  summary,
  giftcards,
  appliedBundle,
}: {
  selectedPaymentMethod: SelectedPaymentMethod;
  summary: CheckoutSummary;
  giftcards?: Giftcard[];
  appliedBundle?: ClientBundle;
}): CheckoutTracking.PaymentMethods => {
  const {
    availablePaymentMethods,
    preSelectedPaymentMethod,
    acceptsGiftcard,
    acceptsValuecard,
    acceptsWellnesscard,
    payLater,
  } = summary;
  const isFree = summary.finalWithoutGiftcards === 0;
  const sumToPay = availablePaymentMethods.find((method) => method.type === selectedPaymentMethod.type)?.final;
  const isNoCharge = (!isFree && sumToPay === 0) || (appliedBundle && sumToPay === 0);

  const availableGiftcardMethods: PaymentMethodAvailableItem[] = [];

  if (acceptsGiftcard) availableGiftcardMethods.push('universal_giftcard');
  if (acceptsValuecard) availableGiftcardMethods.push('value_card');
  if (acceptsWellnesscard) availableGiftcardMethods.push('wellness_card');

  return {
    payment_methods_available: (() => {
      if (isFree || isNoCharge) {
        return availableGiftcardMethods;
      }

      return [
        ...availablePaymentMethods
          .map((method) => getPaymentMethodLabel(method.type))
          .filter((method) => method !== 'blank')
          /**
           * filter out duplicate values (for example new cof and stored cof both returns 'card')
           */
          .filter((value, index, self) => self.indexOf(value) === index),
        ...availableGiftcardMethods,
      ];
    })(),
    selected_payment_method: (() => {
      if (isNoCharge) {
        return 'no_charge';
      }

      if (isFree) {
        return 'free';
      }

      return getPaymentMethodLabel(selectedPaymentMethod.type);
    })(),
    pre_selected_payment_method: (() => {
      if (isFree || preSelectedPaymentMethod === undefined) {
        return 'unavailable';
      }

      return getPaymentMethodLabel(preSelectedPaymentMethod.type);
    })(),

    card_payment_flow: isCardPayment(selectedPaymentMethod) ? (payLater ? 'pay_later' : 'pay_now') : 'blank',
    charge_adjustments: (() => {
      const output: ChargeAdjustmentItem[] = [];

      if (appliedBundle) {
        output.push('bundle');
      }

      giftcards?.forEach((giftcard) => {
        switch (giftcard.giftCardType) {
          case 'EGC':
          case 'IGC':
          case 'UGC':
            output.push('universal_giftcard');
            break;
          case 'VCEGC':
          case 'VCIGC':
            output.push('value_card');
            break;
          case 'UGCW':
            output.push('wellness_card');
        }
      });

      return output.filter((value, index, self) => self.indexOf(value) === index) || [];
    })(),
  };
};

const isCardPayment = (paymentMethod: SelectedPaymentMethod) => {
  switch (paymentMethod.type) {
    case PaymentMethod.STORED_ONLINE_CARD:
    case PaymentMethod.APPLE_PAY:
    case PaymentMethod.GOOGLE_PAY:
      return true;
    default:
      return false;
  }
};

const getServiceCampaigns = (services: BookState['services'], campaigns: BookState['campaigns']) => {
  const serviceCampaigns = {};

  if (services && services.length) {
    const servicesIds = services.map((service) => service.id);
    services.forEach((service) => {
      const { campaignService } = getServiceCampaignIfAny(service, campaigns, servicesIds);
      if (campaignService) {
        serviceCampaigns[service.id] = campaignService;
      }
    });
  }
  return serviceCampaigns;
};

export const getBookingSubmitLabel = ({
  paymentMethod,
  isFloating,
  isMobileView,
  isFullyPadWithGiftcard = false,
}: {
  paymentMethod: CheckoutPaymentMethod | SelectedPaymentMethod['type'];
  isFloating?: boolean;
  isMobileView?: boolean;
  isFullyPadWithGiftcard?: boolean;
}) => {
  switch (paymentMethod) {
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
      return isFloating && isMobileView ? _s('bookCTA.continue') : _s('bookCTA.klarna');
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
      return isFloating && isMobileView ? _s('bookCTA.continue') : _s('bookCTA.qliro');
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.SWISH:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF:
    case CHECKOUT_PAYMENT_METHOD.NEW_COF:
      return _s('bookCTA.bookAndPay');
    default:
      if (isFullyPadWithGiftcard) {
        return _s('bookCTA.bookAndPay');
      }
      return _s('bookCTA.default');
  }
};

export const getBundleSubmitLabel = ({
  paymentMethod,
  isFloating,
  isMobileView,
}: {
  paymentMethod: CheckoutPaymentMethod | SelectedPaymentMethod['type'];
  isFloating?: boolean;
  isMobileView?: boolean;
}) => {
  switch (paymentMethod) {
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
      return isFloating && isMobileView ? _s('bookCTA.continue') : _s('bookCTA.klarna');
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
      return isFloating && isMobileView ? _s('bookCTA.continue') : _s('bookCTA.qliro');
    default:
      return _s('Buy');
  }
};

export const isStandardFormField = (fieldName: string) => {
  const standardField = standardFormFieldSchema.safeParse(fieldName);
  return standardField.success;
};

export function getPaymentRedirectResultIfAny(locationState: any, history: any): boolean | undefined {
  const queryParams = new URLSearchParams(locationState?.search);
  const redirectResult = queryParams.has('paymentValidateSuccessRedirect')
    ? queryParams.get('paymentValidateSuccessRedirect')
    : undefined;
  queryParams.delete('paymentValidateSuccessRedirect');
  history?.replace?.({ search: queryParams.toString(), state: locationState?.state });
  if (redirectResult === undefined) return undefined;
  return redirectResult === 'true';
}

export function clearGroupBookingParticipantsSession() {
  const keys = Object.keys(sessionStorage);
  keys.forEach((key) => {
    if (key.endsWith(GROUP_BOOKING_PARTICIPANTS_STORAGE_KEY)) {
      sessionStorage.removeItem(key);
    }
  });
}

export function getGroupBookingParticipantSession(booking: BookState, employeeId: string) {
  const groupSessionStorageName = `${booking.time.capacity}-${employeeId}-${booking.services
    .map((service) => service.id)
    .join('-')}-${GROUP_BOOKING_PARTICIPANTS_STORAGE_KEY}`;
  return JSON.parse(sessionStorage.getItem(groupSessionStorageName));
}

export function saveGroupBookingParticipantSession({
  booking,
  formData,
  employeeId,
}: {
  booking: BookState;
  formData: CheckoutFormData;
  employeeId: string;
}) {
  const sessionStorageName = `${booking.time.capacity}-${employeeId}-${booking.services
    .map((service) => service.id)
    .join('-')}-${GROUP_BOOKING_PARTICIPANTS_STORAGE_KEY}`;

  if (Object.keys(formData).length === 0) {
    sessionStorage.removeItem(sessionStorageName);
  } else {
    sessionStorage.setItem(sessionStorageName, JSON.stringify(formData));
  }
}

/**
 * Store user preferences for guest checkout in sessionStorage to persist options
 * between qliro/klarna/cof redirects
 */
export function guestCheckoutManager() {
  const GUEST_CHECKOUT_SESSIONSTORAGE_KEY = 'guest-checkout-options';
  const guestCheckoutOptionsSchema = z.object({
    useGuestCheckout: z.boolean().optional(),
    isMediator: z.boolean().optional(),
    promptedEmail: z.boolean().optional(),
  });

  const getGuestCheckoutOptions = () => {
    const validateStoredGuestCheckoutOptions = guestCheckoutOptionsSchema.safeParse(
      JSON.parse(sessionStorage.getItem(GUEST_CHECKOUT_SESSIONSTORAGE_KEY)),
    );

    return validateStoredGuestCheckoutOptions.success ? validateStoredGuestCheckoutOptions.data : undefined;
  };

  const setGuestCheckoutOptions = (options: z.infer<typeof guestCheckoutOptionsSchema>) => {
    const existing = getGuestCheckoutOptions() || {};
    sessionStorage.setItem(GUEST_CHECKOUT_SESSIONSTORAGE_KEY, JSON.stringify({ ...existing, ...options }));
  };

  return {
    getOptions: getGuestCheckoutOptions,
    setOptions: setGuestCheckoutOptions,
  };
}

export function getCheckoutTermsVariant(selectedPaymentMethod: SelectedPaymentMethod): TermLinkIdentifier {
  switch (selectedPaymentMethod.type) {
    case CHECKOUT_PAYMENT_METHOD.APPLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY:
    case CHECKOUT_PAYMENT_METHOD.STORED_COF:
    case CHECKOUT_PAYMENT_METHOD.NEW_COF:
    case CHECKOUT_PAYMENT_METHOD.SWISH:
    case CHECKOUT_PAYMENT_METHOD.QLIRO:
      return TermLinkIdentifier.PAYMENT;
    case CHECKOUT_PAYMENT_METHOD.KLARNA:
    case CHECKOUT_PAYMENT_METHOD.PAY_AT_PLACE:
      return TermLinkIdentifier.USAGE;
  }

  return TermLinkIdentifier.USAGE;
}

export function getCheckoutTerms(
  type: 'booking' | 'bundle' | 'giftcard',
  variant: TermLinkIdentifier,
  buttonLabel: string,
  merchant?: string,
): {
  termsPageId: number;
  description: string;
  linkLabel: string;
} {
  const baseTranslationKey = 'checkoutTerms';
  const WP_TERMS_PAGE_ID = { usage: 3731, payment: 3729, coupon: 3724 } as const;
  const description = _s(`${baseTranslationKey}.description.${type}.${variant}`, { buttonLabel, merchant });

  /**
   * If we apply gc to booking checkout we want to show usage terms instead of coupon terms
   * and paying for a bundle we want to show the coupon terms
   */
  let overrideVariant = (() => {
    if (variant === TermLinkIdentifier.COUPON && type === 'booking') return TermLinkIdentifier.USAGE;
    if (variant === TermLinkIdentifier.PAYMENT && type === 'bundle') return TermLinkIdentifier.COUPON;
    return variant;
  })();

  const termsPageId = WP_TERMS_PAGE_ID[overrideVariant];
  const linkLabel = TERMS_NAVIGATION_LINKS.find((link) => link.identifier === overrideVariant)?.label;
  const shortenLinkLabel = (() => {
    if (type === 'bundle') {
      return _s(`${baseTranslationKey}.terms-link-label.bundlePayment`);
    }

    if (overrideVariant === TermLinkIdentifier.USAGE) {
      return _s(`${baseTranslationKey}.terms-link-label.general`);
    }

    return linkLabel;
  })();

  return { termsPageId, linkLabel: shortenLinkLabel, description };
}

export function getCheckoutSubmitIconVariantIfAny(paymentMethod: SelectedPaymentMethod): IconVariant | undefined {
  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.APPLE_PAY) {
    return 'payment-method-apple-pay-lg';
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.GOOGLE_PAY) {
    return 'payment-method-google-pay-lg';
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.QLIRO) {
    return 'payment-method-qliro-lg';
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.KLARNA) {
    return 'payment-method-klarna-lg';
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.SWISH) {
    return 'payment-method-swish-lg';
  }

  if (paymentMethod.type === CHECKOUT_PAYMENT_METHOD.STORED_COF) {
    switch (paymentMethod.brand) {
      case 'amex':
      case 'amex_applepay':
      case 'amex_googlepay':
        return 'payment-method-amex-lg';
      case 'diners':
        return 'payment-method-diners-lg';
      case 'discover':
      case 'discover_applepay':
      case 'discover_googlepay':
        return 'payment-method-discover-lg';
      case 'maestro':
      case 'maestro_applepay':
      case 'maestro_googlepay':
        return 'payment-method-maestro-lg';
      case 'mc':
      case 'mc_applepay':
      case 'mc_googlepay':
        return 'payment-method-mastercard-lg';
      case 'visa':
      case 'visa_applepay':
      case 'visa_googlepay':
        return 'payment-method-visa-lg';
      default:
        return undefined;
    }
  }

  return undefined;
}

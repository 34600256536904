import { config } from '@/config';
import { isServer } from '@/helpers';
import { _s } from '@/locale';
import { NavItem, NavItemIdentifier } from '@/types/navigation';
import { User } from '@/types/user';

export const navigationDrawerConstants = {
  NAVIGATION_DRAWER: 'NAVIGATION_DRAWER',
};

export type DrawerItem = NavItem & {
  category: 'account' | 'offer' | 'more';
};

const tBase = (key: string) => _s(`headerNavigation.${key}`);

const supportItem: DrawerItem = {
  category: 'more',
  icon: 'support',
  label: tBase('support'),
  to: '/support',
  identifier: NavItemIdentifier.Support,
};

const companyItem = (isMerchant: boolean): DrawerItem => ({
  category: 'more',
  icon: 'company',
  label: isMerchant ? tBase('manageCompany') : tBase('connectCompany'),
  to: isMerchant ? config.merchantUrl : 'https://business.bokadirekt.se/',
  identifier: isMerchant ? NavItemIdentifier.CompanyLogin : NavItemIdentifier.ConnectCompany,
  externalLink: true,
});

const cancelItem: DrawerItem = {
  category: 'account',
  icon: 'calendar-cross',
  label: tBase('cancel'),
  to: '/cancel',
  identifier: NavItemIdentifier.Cancel,
};

const myBundlesItem: DrawerItem = {
  category: 'account',
  icon: 'punch-card',
  label: tBase('myBundles'),
  to: '/klippkort/giltig',
  identifier: NavItemIdentifier.Bundles,
};

export const navigationDrawerItems = (user: User, hasNewGiftcardCheckoutVariant: boolean): DrawerItem[] => {
  const isMerchant = user?.about?.isMerchant;
  const whitelabel = !isServer ? sessionStorage.getItem('whitelabel') === 'true' : false;

  return user
    ? [
        {
          category: 'account',
          icon: 'account',
          label: tBase('myProfile'),
          to: '/my-profile',
          identifier: NavItemIdentifier.Profile,
        },
        {
          category: 'account',
          icon: 'cog',
          label: tBase('accountSettings'),
          to: '/account-settings',
          identifier: NavItemIdentifier.AccountSettings,
        },
        cancelItem,
        {
          category: 'account',
          icon: 'credit-card-hand',
          label: tBase('paymentMethods'),
          to: '/betalningsmetoder',
          identifier: NavItemIdentifier.PaymentMethods,
        },
        myBundlesItem,
        ...getOfferItems(whitelabel, hasNewGiftcardCheckoutVariant),
        ...(!whitelabel ? [companyItem(isMerchant)] : []),
        supportItem,
      ]
    : [
        cancelItem,
        ...getOfferItems(whitelabel, hasNewGiftcardCheckoutVariant),
        ...(!whitelabel ? [companyItem(isMerchant)] : []),
        supportItem,
      ];
};

const getOfferItems = (whitelabel: boolean, hasNewGiftcardCheckoutVariant: boolean): DrawerItem[] => {
  const offerItemsOnWhitelabel = [NavItemIdentifier.GiftCardUse, NavItemIdentifier.WellnessCardUse];
  const offerItems: DrawerItem[] = [
    {
      category: 'offer',
      icon: 'deal',
      label: 'Sistaminutentider.se',
      to: 'https://www.sistaminutentider.se/',
      identifier: NavItemIdentifier.Sistaminutentider,
      externalLink: true,
    },
    {
      category: 'offer',
      icon: 'gift-card',
      label: tBase('buyGiftCard'),
      to: hasNewGiftcardCheckoutVariant ? '/giftcard/checkout' : '/giftcards/buy',
      identifier: NavItemIdentifier.GiftCardBuy,
    },
    {
      category: 'offer',
      icon: 'gift-card',
      label: tBase('useGiftCard'),
      to: '/giftcards',
      identifier: NavItemIdentifier.GiftCardUse,
    },
    {
      category: 'offer',
      icon: 'wellness-card',
      label: tBase('buyWellnessCard'),
      to: hasNewGiftcardCheckoutVariant ? '/wellnesscard/checkout' : '/giftcards/buy?ugctype=wellness',
      identifier: NavItemIdentifier.WellnessCardBuy,
    },
    {
      category: 'offer',
      icon: 'wellness-card',
      label: tBase('useWellnessCard'),
      to: '/giftcards',
      identifier: NavItemIdentifier.WellnessCardUse,
    },
  ];
  return offerItems.filter((item) => (whitelabel ? offerItemsOnWhitelabel.includes(item.identifier) : true));
};

import { ListItem, ListItemContent } from '@/components/elements/lists';
import DataGrid from '@/components/elements/lists/DataGrid';
import Icon from '@/components/icons/Icon';
import { _s } from '@/locale';
import { PHYSICAL_FEE } from '@/pages/giftcard/checkout/GiftcardCheckout.hooks';
import { SelectedPaymentMethod as PaymentMethod } from '@/types/checkout';
import SelectedPaymentMethod from '../../checkout/SelectedPaymentMethod/SelectedPaymentMethod';

const baseTranslationKey = 'components.templates.giftcards.CheckoutDetails';

export type CheckoutDetailsProps = {
  selectedAmount: number;
  selectedQuantity: number;
  productType: 'giftcard' | 'wellnesscard';
  deliveryType: 'physical' | 'digital';
  paymentMethod?: PaymentMethod;
  discount?: { code?: string; amount?: number };
};

const CheckoutDetails = ({
  selectedAmount,
  productType,
  deliveryType,
  selectedQuantity,
  paymentMethod,
  discount,
}: CheckoutDetailsProps) => {
  const isGiftcard = productType === 'giftcard';
  return (
    <div className="pl-lg flex flex-col">
      <ListItem
        leftPadding={false}
        leftSlot={
          <Icon
            alt=""
            size="md"
            variant={isGiftcard ? 'payment-method-gift-card' : 'payment-method-wellness-card'}
            noFilter
          />
        }
        verticalAlign="top">
        <ListItemContent
          content={_s(`${baseTranslationKey}.content.${productType}`)}
          title={_s(`${baseTranslationKey}.title.${productType}`)}
        />
      </ListItem>

      <div className="pr-lg">
        <DataGrid.Row hasPaddingTop>
          <DataGrid.Cell
            title={`${selectedQuantity} x ` + _s(`${baseTranslationKey}.itemTitle.${deliveryType}.${productType}`)}
            type="muted"
            align="left"
          />
          <div>
            <DataGrid.Cell title={`${selectedAmount} kr`} align="right" />
          </div>
        </DataGrid.Row>

        <DataGrid.Row undeline>
          <DataGrid.Cell title={_s(`${baseTranslationKey}.itemContent.${deliveryType}`)} type="description" />
        </DataGrid.Row>

        {(Boolean(discount?.code) || Boolean(discount?.amount)) && (
          <>
            <DataGrid.Row hasPaddingTop>
              <DataGrid.Cell title={_s(`${baseTranslationKey}.discount.title`)} type="muted" />
              <DataGrid.Cell
                title={`-${discount.amount}kr`}
                align="right"
                type="discount"
                ariaLabel={_s(`${baseTranslationKey}.discount.ariaLabel`)}
              />
            </DataGrid.Row>
            {Boolean(discount?.code) && (
              <DataGrid.Row undeline>
                <DataGrid.Cell title={discount.code} type="description" />
              </DataGrid.Row>
            )}
          </>
        )}

        <DataGrid.Row hasPaddingTop>
          <DataGrid.Cell title={_s(`${baseTranslationKey}.valueTitle`)} type="header" />
          <DataGrid.Cell
            title={`${
              selectedAmount * selectedQuantity +
              (deliveryType === 'physical' ? PHYSICAL_FEE : 0) -
              (discount?.amount ? discount.amount : 0)
            } kr`}
            type="header"
            align="right"
          />
        </DataGrid.Row>
      </div>
      {paymentMethod && <SelectedPaymentMethod method={paymentMethod} />}
    </div>
  );
};

export default CheckoutDetails;

import { InitialsAvatar } from '@/components/elements/redesign/Avatar';
import { LogoIcon, SistaMinutenLogoIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { PageLayoutType } from '@/components/layouts/types';
import AddPaymentMethodPromotion from '@/components/modules/modals/AddPaymentMethodPromotion';
import { NEW_GIFTCARD_CHECKOUT_FEATURE_FLAG } from '@/constants/experimentConstants';
import { navBarMainNavigationItems } from '@/constants/navBarConstants';
import { NAVIGATION_TRIGGER } from '@/constants/navigation';
import { DEFAULT_NAVBAR_HEIGHT, Z_INDEX } from '@/constants/styleConstants';
import { classnames, getUserInitials, isSistaminuten } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { getScreenName, handleNavItemClicked } from '@/services/navigationServices';
import { NavItem, NavItemIdentifier } from '@/types/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import NavigationDrawer from '../../NavigationDrawer/NavigationDrawer';
import NavBarItem from '../NavBarItem/NavBarItem';

export type NavBarProps = {
  title?: string;
  titleAction?: () => void;
  user?: Record<string, any>;
  source?: string;
  type?: PageLayoutType;
  mainNavItems?: NavItem[];
  subNavItems?: NavItem[];
  back?: boolean;
  showLogo?: boolean;
  onBackButtonClick?: () => void;
  backSrc?: string;
  rightSlot?: React.ReactNode;
  backIcon?: IconProps['variant'];
  headerSlot?: React.ReactNode;
  floatingSlot?: React.ReactNode;
  navClass?: string;
  hideOnScroll?: boolean;
  prepend?: React.ReactNode;
  defaultNavBarHeight?: number;
};

const MainNav = ({
  mainNavItems,
  user,
  source,
  back,
  title,
  titleAction,
  onBackButtonClick,
  backSrc,
  backIcon,
  showLogo = true,
}: NavBarProps) => {
  const dispatch = useDispatch();
  const flags = useAppSelector((state) => state.flags);
  const handleClick = handleNavItemClicked(dispatch, getScreenName(source), NAVIGATION_TRIGGER.TOP_NAVBAR);
  const routeMatch = useRouteMatch();
  const getVariant = useGetAmplitudeExperimentVariant();
  const location = useLocation();
  const { isMobileView } = useMobileView();
  const [showAddPaymentMethodPromotion, setShowAddPaymentMethodPromotion] = useState(false);

  const handleClosePaymentMethodPromo = () => {
    localStorage.setItem('closedPaymentMethodPromo', 'true');
    setShowAddPaymentMethodPromotion(false);
    handleClick(NavItemIdentifier.Hamburger)();
  };

  return (
    <>
      <div className="gap-sm flex items-center">
        {back && (
          <Link
            onClick={onBackButtonClick ?? (!backSrc ? onBackButtonClick : null)}
            aria-label={_s('back')}
            to={backSrc ?? ''}>
            <Icon variant={backIcon ?? 'back'} />
          </Link>
        )}
        {showLogo && (
          <Link to="/" aria-label={isSistaminuten() ? 'Sistaminutentider' : 'Bokadirekt'}>
            {isSistaminuten() ? <SistaMinutenLogoIcon className="h-6" /> : <LogoIcon className="h-[22px]" />}
          </Link>
        )}
        {isMobileView && !showLogo && title && (
          <>
            <span className="text-black-900 max-w-[290px] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold sm:max-w-min">
              {title}
            </span>
          </>
        )}
        {titleAction && (
          <button onClick={titleAction} className="outline-none">
            <Icon variant="chevron-down" />
          </button>
        )}
      </div>
      <div className="gap-xl flex">
        {!isMobileView &&
          (
            mainNavItems ??
            navBarMainNavigationItems(user, flags, getVariant(NEW_GIFTCARD_CHECKOUT_FEATURE_FLAG).value === 'on')
          ).map((item, i) => {
            const isListPage = routeMatch.path === '/:q/:location' && item.to === '/vad/var';
            const selected =
              !isListPage && location.search ? item.to?.includes(location.search) : routeMatch.path === item.to;
            return (
              <NavBarItem
                key={i}
                item={{ ...item, selected: isListPage || selected }}
                handleClick={() => {
                  if (
                    item.identifier === NavItemIdentifier.Hamburger &&
                    localStorage.getItem('closedPaymentMethodPromo') !== 'true' &&
                    !user?.activeCoF &&
                    user
                  ) {
                    setShowAddPaymentMethodPromotion(true);
                  } else {
                    handleClick(item.identifier)();
                  }
                }}
              />
            );
          })}

        {isMobileView && !isSistaminuten() && (
          <>
            {user ? (
              <Link to="/my-profile">
                <InitialsAvatar initials={getUserInitials(user)} size="sm" variant="default" />
              </Link>
            ) : (
              <NavBarItem
                item={{ icon: 'account', label: _s('logIn'), identifier: NavItemIdentifier.LogIn, selected: true }}
                handleClick={() => {
                  handleClick(NavItemIdentifier.LogIn)();
                }}
              />
            )}
          </>
        )}
      </div>
      <AddPaymentMethodPromotion
        key="add-payment-method-promotion"
        isOpen={showAddPaymentMethodPromotion}
        onClose={handleClosePaymentMethodPromo}
      />
    </>
  );
};

const SubNav = ({
  title,
  back,
  subNavItems,
  onBackButtonClick,
  source,
  backSrc,
  backIcon,
  rightSlot,
  titleAction,
}: NavBarProps) => {
  const dispatch = useDispatch();
  const handleClick = handleNavItemClicked(dispatch, getScreenName(source));
  return (
    <>
      <span className="gap-sm flex flex-1">
        {back && (
          <>
            {backSrc && (
              <Link aria-label={_s('back')} to={backSrc}>
                <Icon variant={backIcon ?? 'back'} />
              </Link>
            )}
            {!backSrc && (
              <button onClick={onBackButtonClick} aria-label={_s('back')} className="outline-none">
                <Icon variant={backIcon ?? 'back'} />
              </button>
            )}
          </>
        )}
        {title && (
          <>
            <span className="text-black-900 max-w-[290px] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold sm:max-w-min">
              {title}
            </span>
            {titleAction && (
              <button onClick={titleAction} className="outline-none">
                <Icon variant="chevron-down" />
              </button>
            )}
          </>
        )}
      </span>
      <div className="gap-sm flex items-center">
        {subNavItems?.map((item, i) => (
          <NavBarItem key={i} item={item} handleClick={() => handleClick(item.identifier)} />
        ))}
      </div>
      {rightSlot && <div className="flex items-center justify-center">{rightSlot}</div>}
    </>
  );
};

const NavBar = ({
  type = 'mainView',
  headerSlot,
  floatingSlot,
  prepend,
  navClass,
  hideOnScroll = false,
  defaultNavBarHeight = DEFAULT_NAVBAR_HEIGHT,
  ...props
}: NavBarProps) => {
  const history = useHistory();
  const user = useAppSelector((state) => state.users?.user);
  const { isMobileView } = useMobileView();
  const [show, setShow] = useState(true);
  const navBarRef = useRef<HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState(undefined);
  const defaultGoBack = () => history.go(-1);

  const updateNavHeight = useCallback(() => {
    const currentHeight = navBarRef?.current?.offsetHeight ?? 0;
    currentHeight !== navBarHeight && setNavBarHeight(currentHeight);
  }, [navBarHeight]);

  useEffect(() => {
    if (!navBarRef.current) return;
    const { current } = navBarRef;
    setNavBarHeight(current.offsetHeight);
    window.addEventListener('scroll', updateNavHeight);

    return () => {
      window.removeEventListener('scroll', updateNavHeight);
    };
  }, [updateNavHeight]);

  useEffect(() => {
    // set show to false when scrolling down 200px, and true when scrolling up 10px from when its hidden
    if (hideOnScroll && isMobileView) {
      let prevScrollPos = window.pageYOffset;
      const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isScrollingDown = prevScrollPos < currentScrollPos;
        const isScrollingUp = prevScrollPos > currentScrollPos;
        const isScrollingDownMoreThan200px = currentScrollPos > 200;
        const isScrollingUpMoreThan10px = currentScrollPos < prevScrollPos - 10;
        if (isScrollingDown && isScrollingDownMoreThan200px) {
          setShow(false);
        } else if (isScrollingUp && isScrollingUpMoreThan10px) {
          setShow(true);
        }
        prevScrollPos = currentScrollPos;
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      setShow(true);
    }
  }, [isMobileView]);

  return (
    <header>
      <nav
        className={classnames(
          `shadow-xs !fixed top-0 w-full bg-white duration-300 ${navClass}`,
          !show && `-translate-y-[64px]`,
        )}
        ref={navBarRef}
        style={{ zIndex: Z_INDEX.NAVBAR }}>
        {prepend}
        <div className={classnames(`p-lg gap-md flex h-[64px] items-center justify-between`)}>
          {type === 'mainView' ? (
            <MainNav {...props} user={user} onBackButtonClick={props.onBackButtonClick ?? defaultGoBack} />
          ) : (
            <SubNav {...props} user={user} onBackButtonClick={props.onBackButtonClick ?? defaultGoBack} />
          )}
        </div>
        {headerSlot}
        {!isMobileView && <NavigationDrawer />}
      </nav>
      {floatingSlot && (
        <div
          className={classnames(`fixed top-0 z-20 w-full duration-300`, !show && '-translate-y-[64px]')}
          style={{ top: navBarHeight ?? defaultNavBarHeight }}>
          {floatingSlot}
        </div>
      )}
      {/* Spacer */}
      <div className="bg-transparent" style={{ height: navBarHeight ?? defaultNavBarHeight }}></div>
    </header>
  );
};

export default NavBar;

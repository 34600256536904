import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type UseGiftcardCheckoutFormDataContext = ReturnType<typeof useGiftcardCheckoutFormDataManager>;

const GiftcardCheckoutFormDataContext = createContext<UseGiftcardCheckoutFormDataContext>(null);

export const formDataSchema = z.object({
  amount: z.number().optional(),
  customAmount: z.number().optional(),
  quantity: z.number(),
  email: z.union([z.string().email(), z.string().length(0)]).optional(),
  name: z.string().optional(),
  address: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  recipientName: z.string().optional(),
  message: z.string().optional(),
  giverName: z.string().optional(),
  ssn: z.string().optional(),
  serviceType: z.enum(['massage', 'pt', 'yoga']).optional(),
  type: z.enum(['digital', 'physical']),
  discountCode: z.string().optional(),
});

export type GiftcardFormData = z.TypeOf<typeof formDataSchema>;

const useGiftcardCheckoutFormDataManager = (initialData?: GiftcardFormData) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    unregister,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<GiftcardFormData>({
    defaultValues: initialData ?? { amount: 1500, quantity: 1, type: 'digital' },
  });

  const [
    amount,
    customAmount,
    quantity,
    type,
    email,
    name,
    address,
    postalCode,
    city,
    recipientName,
    message,
    giverName,
    ssn,
    serviceType,
    discountCode,
  ] = watch([
    'amount',
    'customAmount',
    'quantity',
    'type',
    'email',
    'name',
    'address',
    'postalCode',
    'city',
    'recipientName',
    'message',
    'giverName',
    'ssn',
    'serviceType',
    'discountCode',
  ]);

  useEffect(() => {
    clearErrors(['address', 'city', 'email', 'name', 'postalCode']);
  }, [type, clearErrors]);

  return {
    formdata: {
      amount,
      customAmount,
      quantity,
      type,
      email,
      name,
      address,
      postalCode,
      city,
      recipientName,
      message,
      giverName,
      ssn,
      serviceType,
      discountCode,
    },
    register,
    handleSubmit,
    setValue,
    errors,
    unregister,
    reset,
  };
};

export const GiftcardCheckoutFormDataProvider = ({
  children,
  initialData,
}: {
  children: ReactNode;
  initialData?: GiftcardFormData;
}) => {
  const checkoutForm = useGiftcardCheckoutFormDataManager(initialData);
  return (
    <GiftcardCheckoutFormDataContext.Provider value={checkoutForm}>{children}</GiftcardCheckoutFormDataContext.Provider>
  );
};

export const useGiftcardCheckoutFormData = () => {
  const context = useContext(GiftcardCheckoutFormDataContext);

  if (!context) {
    throw new Error('useGiftcardCheckoutFormData must be used within a GiftcardCheckoutFormDataProvider');
  }

  return context;
};

import Header from '@/components/elements/Header/Header';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import { ListItem, ListItemContent } from '@/components/elements/lists';
import { InitialsAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import CardWrapper from '@/components/modules/CardWrapper';
import { getUserInitials } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { useGiftcardCheckoutFormData } from '@/hooks/useGiftcardCheckoutFormData';
import { _s } from '@/locale';
import { useState } from 'react';
import isEmail from 'validator/lib/isEmail';

const baseTranslationKey = 'components.modules.checkout.giftcards.DeliveryInfo';

type DeliveryInfoProps = {
  content?: string;
  subTitle?: string;
  onClick?: () => void;
  productType: 'giftcard' | 'wellnesscard';
};

const DeliveryInfo = ({ onClick, content, subTitle, productType }: DeliveryInfoProps) => {
  const { register, setValue, formdata, errors } = useGiftcardCheckoutFormData();
  const [sendToOther, setSendToOther] = useState(false);
  const user = useAppSelector((state) => state.users.user);
  const isMissingAdress = !user?.contact?.streetAddress;
  const userInitials = user ? getUserInitials(user) : '';
  const titleTranslation =
    !user && formdata.type === 'digital' ? _s(`${baseTranslationKey}.guest.title`) : _s(`${baseTranslationKey}.title`);
  const sendToOtherTitle =
    formdata.type === 'digital'
      ? _s(`${baseTranslationKey}.digital.sendToOther.${productType}`)
      : _s(`${baseTranslationKey}.physical.sendToOther.${!isMissingAdress ? 'address' : 'person'}`);
  const isAddressRequired = (!user || sendToOther) && productType === 'giftcard' && formdata.type === 'physical';

  const handleSendToOtherClick = () => {
    if (sendToOther) {
      setValue('email', undefined);
      setValue('name', undefined);
      setValue('address', undefined);
      setValue('postalCode', undefined);
      setValue('city', undefined);
    }
    setSendToOther((prevState) => !prevState);
  };

  return (
    <CardWrapper>
      <div className="pb-lg flex flex-col">
        <div className="pl-lg py-md">
          <Header size="md" label={titleTranslation} />
        </div>

        {user && (
          <ListItem
            leftSlot={<InitialsAvatar size="md" variant="default" initials={userInitials} />}
            onClick={onClick}
            rightSlot={<Icon variant="chevron-s-right" />}
            underline>
            <ListItemContent content={content} subTitle={subTitle} title={user?.about?.name ?? ''} />
          </ListItem>
        )}

        {user && formdata.type === 'physical' && (
          <ListItem
            leftSlot={
              <button
                type="button"
                aria-checked
                className="block outline-none"
                onClick={handleSendToOtherClick}
                role="checkbox">
                <Icon variant={sendToOther ? 'checkbox-checked' : 'checkbox-unchecked'} />
              </button>
            }>
            <ListItemContent title={sendToOtherTitle} />
          </ListItem>
        )}
        <div style={{ display: (sendToOther || isMissingAdress) && formdata.type === 'physical' ? 'block' : 'none' }}>
          <div style={{ display: (sendToOther || !user) && formdata.type === 'physical' ? 'block' : 'none' }}>
            <ListInput
              {...register('name', {
                required: isAddressRequired ? _s(`${baseTranslationKey}.guest.error.name`) : false,
              })}
              label={_s(`${baseTranslationKey}.physical.input.label.name`)}
              error={errors.name ? errors.name.message : ''}
              placeholder={_s(`${baseTranslationKey}.physical.input.placeholder.name`)}
              id="name"
            />
          </div>
          <ListInput
            {...register('address', {
              required: isAddressRequired ? _s(`${baseTranslationKey}.guest.error.address`) : false,
            })}
            label={_s(`${baseTranslationKey}.physical.input.label.street`)}
            error={errors.address ? errors.address.message : ''}
            placeholder={_s(`${baseTranslationKey}.physical.input.placeholder.street`)}
            id="address"
          />
          <div className="gap-lg flex">
            <ListInput
              {...register('postalCode', {
                required: isAddressRequired ? _s(`${baseTranslationKey}.guest.error.postalCode`) : false,
              })}
              label={_s(`${baseTranslationKey}.physical.input.label.postalCode`)}
              error={errors.postalCode ? errors.postalCode.message : ''}
              placeholder={_s(`${baseTranslationKey}.physical.input.placeholder.postalCode`)}
              id="postalCode"
            />
            <ListInput
              {...register('city', {
                required: isAddressRequired ? _s(`${baseTranslationKey}.guest.error.locality`) : false,
              })}
              label={_s(`${baseTranslationKey}.physical.input.label.locality`)}
              error={errors.city ? errors.city.message : ''}
              placeholder={_s(`${baseTranslationKey}.physical.input.placeholder.locality`)}
              id="city"
            />
          </div>
        </div>

        <div
          className={`flex flex-col ${!user && formdata.type === 'physical' ? 'pt-xl' : ''}`}
          style={{
            display: !user ? 'block' : 'none',
          }}>
          <ListInput
            {...register('email', {
              required: !user ? _s(`${baseTranslationKey}.guest.error.email`) : false,
              validate: (value) => (!user && !isEmail(value.trim()) ? _s('invalidEmail') : true),
            })}
            error={errors.email ? errors.email.message : ''}
            {...(!user ? { label: _s(`${baseTranslationKey}.guest.inputLabel.${productType}`) } : {})}
            type="email"
            placeholder="exempel@email.com"
            id="email"
          />
        </div>
      </div>
    </CardWrapper>
  );
};

export default DeliveryInfo;
